import React from 'react';

import { DashedAnimationsItem, IDashedAnimationsItemProps} from 'components/dashed-animation/DashedAnimationsItem'

export interface IDashedAnimationsProps {
  items: Array<IDashedAnimationsItemProps>;
  isActive: boolean;
  isActiveMobile: boolean;
}

export const DashedAnimations = ({ items, isActive, isActiveMobile }: IDashedAnimationsProps) => {
  
  const content = items.map((item: IDashedAnimationsItemProps, index: number) => 
    <DashedAnimationsItem key={index} item={item} isActive={isActive} isActiveMobile={isActiveMobile} />
  )

  return (
    <>
      {content}
    </>
  )
}
  