import { INLINES } from '@contentful/rich-text-types';
import React from 'react';


export default {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return <a href={node.data.uri}
                target={node.data.uri.startsWith('/') || node.data.uri.startsWith('https://aurora.tech/') ? '' : "_blank"}>
                    {children}
                </a>
      }
    }
  }