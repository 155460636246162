import React from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from 'utils/richTextOptions';
import s from './RichText.scss';

interface IProps {
  children: any;
  pageThemeLight?: boolean;
  fontSizeBig?: boolean;
}

export const RichText = ({ pageThemeLight=false, fontSizeBig=false, richText, quoted=false }: any) => {
  return (
    <div className={s('richText', {pageThemeLight}, {fontSizeBig}, {quoted} ) }>
      {renderRichText(richText, richTextOptions)}
    </div>
  );
};
