import { Container } from "components/container/Container";
import { RichText } from "components/contentful/rich-text/RichText";
import React, { useEffect, useRef, useState } from "react";
import playIcon from "assets/images/play-triangle.png";
import s from "./VideoItem.scss";
import { Button } from "components/button/Button";
import { GatsbyImage } from "gatsby-plugin-image";

export const VideoItem = ({
  item,
  style,
  justifyLeft = false,
  borderBottomLeftRadius = false,
  borderRadiusTop = false,
  forceNoControls = false //this is only desktop, unused for now
}: any) => {
  const ref = useRef(null);
  const { videoUrl } = item;
  const [videoPlayer, setVideoPlayer] = useState(null);
  const [state, setState] = useState("looping");
  const [showPlayButton, setShowPlayButton] = useState(true);
  const { autoplay } = item;
  const borderRadiusAll = true;

  useEffect(() => {
    if (state == "playing") {
      videoPlayer.muted = true;
      videoPlayer.volume = 0.5;
      videoPlayer.loop = false;
      videoPlayer.toggleControls(true);
      videoPlayer.restart();
      videoPlayer.play();
    }
  }, [state]);

  useEffect(() => {
    const isMobile = window.matchMedia("only screen and (max-width: 768px)")
      .matches;
    const controls =
      autoplay && !isMobile ? [] : ["play", "progress", "volume"];
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");
      if (ref.current) {
        const player = new Plyr(ref.current, {
          controls: controls,
          loop: { active: true },
          autoplay: autoplay && !isMobile,
          muted: true,
          playsInline: true,
          clickToPlay: isMobile,
          fullscreen: { enabled: false },
          hideControls: !showPlayButton
        });
        player.toggleControls(false);
        setVideoPlayer(player);
      }
    }
    if (ref.current) {
      if (state === "looping" && autoplay) {
        ref.current.muted = true;
        ref.current.setAttribute("muted", "");
        ref.current.setAttribute("playsinline", "");
        if (!isMobile) {
          ref.current.setAttribute("autoplay", "");
        }
        setShowPlayButton(isMobile);
      }
    }
    return () => {
      setVideoPlayer(null);
    };
  }, []);

  useEffect(() => {
    if (videoUrl && videoPlayer) {
      if (videoUrl.includes("vimeo")) {
        videoPlayer.source = {
          type: "video",
          sources: [
            {
              src: item.videoUrl,
              provider: "vimeo",
              size: 1080
            }
          ]
        };
      }
    }
  }, [videoPlayer]);
  return (
    <Container style={style}>
      {item.text && (
        <div
          className={`${s.text} ${(justifyLeft ? s.justifyLeft : "",
          s.spaceVideo)}`}
        >
          <RichText richText={item.text} />
        </div>
      )}
      {(item.video || videoUrl) && (
        <div
          className={s("videoContainer", {
            borderBottomLeftRadius,
            borderRadiusTop,
            borderRadiusAll
          })}
          style={{ position: "relative" }}
        >
          {state == "looping" && showPlayButton && (
            <div className={s.overlay}>
              <button
                onClick={() => {
                  setState("playing");
                }}
                className={s.playButton}
              >
                <img style={{ width: "10px" }} src={playIcon} /> PLAY NOW
              </button>
            </div>
          )}
          <video
            className={s("player")}
            id="player"
            ref={ref}
            playsInline
            muted
            style={{
              "--plyr-control-icon-size": "20px",
              "--plyr-video-control-color": "#1368ea",
              "--plyr-video-control-color-hover": "#1368ea",
              "--plyr-video-controls-background": "transparent",
              "--plyr-color-main": "#fff",
              "--plyr-control-spacing": "20px",
              "--plyr-video-background": "#000000"
            }}
            data-poster={item?.poster}
          >
            {" "}
            {item.video && (
              <source
                src={item.video && item.video.file.url}
                type="video/mp4"
              />
            )}
          </video>
        </div>
      )}
      {!item.video && !videoUrl && item.image && (
        <GatsbyImage
          className={s.videoContainer}
          image={item.image.gatsbyImageData}
          alt={item.image.description}
        />
      )}

      {item.cta && (
        <div className={s.ctaContainer}>
          <Button href={item.cta.href} label={item.cta.label} />
        </div>
      )}
    </Container>
  );
};
