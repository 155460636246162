import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { scroll } from "constants/Scroll";
import { PrismicImage } from "types/prismicImage";
import s from "./Image.scss";

interface IProps {
  image: PrismicImage;
  aspectRatio?: string;
  className?: string;
  loading?: "eager" | "lazy";
  preload?: boolean;
  srcSetMobile?: boolean;
  notFullWidth?: boolean;
}

export const Image = ({
  image,
  aspectRatio,
  className,
  loading = "lazy",
  preload = false,
  srcSetMobile = false
}: any) => {
  //const srcSet = !src2x ? undefined : `${src} 1x, ${src2x} 2x, ${src2x} 1200w`;
  const width = image && image?.file.details.image.width;
  const height = image && image?.file.details.image.height;
  const aspectRatioValue =
    image && (aspectRatio ? aspectRatio : `${(height / width) * 100}%`);
  const getFormatUrl = (fileUrl: string) => {
    let format = "";
    if (fileUrl.includes(".gif")) format = "fm=webp";
    else if (fileUrl.includes(".png")) format = "fm=webp&q=75";
    else if (fileUrl.includes("svg")) format = "fm=svg";
    else format = "fm=jpg&q=75";
    return `${fileUrl}?${format}`;
  };

  const url = image && getFormatUrl(image.file.url);
  const alt = image && image.description;
  const figureRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (loading === "lazy") {
        ScrollTrigger.create({
          scroller: scroll.container,
          trigger: figureRef.current,
          start: "-200% 100%",
          onEnter: () => {
            if (imgRef.current) {
              imgRef.current.src = imgRef.current?.dataset.src;
              if (imgRef.current?.dataset.srcset) {
                imgRef.current.srcset = imgRef.current?.dataset.srcset;
              }
            }
          }
        });
      }
    }, 1500);
  }, []);

  const imgLoaded = (e: any) => {
    if (e.currentTarget.src.indexOf("data:") === -1) {
      figureRef.current?.setAttribute("data-loaded", "true");
    }
  };

  return (
    <figure
      className={s("figure", loading) + " " + className}
      style={{ paddingTop: aspectRatioValue }}
      ref={figureRef}
    >
      <img
        src={
          loading === "eager"
            ? url
            : require("assets/images/aurora.png").default
        }
        data-src={loading === "lazy" ? url : undefined}
        data-srcset={srcSetMobile ? `${url}&w=750` : undefined}
        sizes={srcSetMobile ? `(max-width: 750px) 750px` : ""}
        alt={alt}
        width={width}
        height={height}
        className={className}
        data-preload={preload}
        ref={imgRef}
        onLoad={loading === "lazy" ? imgLoaded : undefined}
      />
    </figure>
  );
};
