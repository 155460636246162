import React, { useEffect, useRef, useState } from 'react';
import s from './DashedAnimationsItem.scss';

import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from 'constants/Scroll'
import { Breakpoints } from 'constants/Breakpoints'

interface IProps {
  item: IDashedAnimationsItemProps;
  isActive: boolean;
  isActiveMobile: boolean;
}

export interface IDashedAnimationsItemProps {
  shape?: 'circle' | 'square';
  strokeWidth?: string;
  strokeDashArray?: string;
  width?: string;
  top?: string;
  left?: string;
  mobile? : {
    width?: string;
    top?: string;
    left?: string;
  };
}

export const DashedAnimationsItem = ({item, isActive=true, isActiveMobile=true} : IProps) => {

  const shape = item.shape ? item.shape : 'circle'
  const strokeWidth = item.strokeWidth ? item.strokeWidth : '2'
  const strokeDashArray = item.strokeDashArray ? item.strokeDashArray : '3 10'
  const width = item.width ? item.width : '15%'
  const top = item.top ? item.top : '50%'
  const left = item.left ? item.left : '50%'
  const widthMobile = item.mobile && item.mobile.width ? item.mobile.width: width
  const topMobile = item.mobile && item.mobile.top ? item.mobile.top : top
  const leftMobile = item.mobile && item.mobile.left ? item.mobile.left : left

  const containerRef= useRef<HTMLDivElement>(null) ;
  const lineRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  let isMobile:boolean
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    isMobile = window.matchMedia(`(max-width: ${Breakpoints.maxTablet}px)`).matches
  }

  useEffect(() => {

    if(isActive || ( isMobile && isActiveMobile ) ){
      setIsVisible(true)

      setTimeout( () => {
        //const clamp = gsap.utils.clamp(-20, 20);

        gsap.fromTo(wrapperRef.current,
                    { opacity: 0, scale:.4 },
                    { opacity: 1, scale:1, duration:1, ease: 'Power1.easeInOut' });

        gsap.from(lineRef.current, {
          strokeDashoffset: -400,
          duration: 1.5,
          ease: 'Power3.easeOut',
        });

        setTimeout( () => {
          
          if(containerRef){
            containerRef.current.style.width = isMobile ? widthMobile : width
            containerRef.current.style.top = isMobile ? topMobile : top
            containerRef.current.style.left = isMobile ? leftMobile : left
          }
          
          gsap.from(lineRef.current, {
            strokeDashoffset: isMobile ? 180 : 300,
            duration: 10,
            transformOrigin: 'center center',
            ease: 'Power1.easeOut',
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef.current,
              scrub: 1,
              start: "top 88%",
              end: '200%',
              // onUpdate: (self) => {
              //   const scale = Math.abs(clamp(self.getVelocity() / 2000));
              //   gsap.to(lineRef.current, { duration: 0.3, scale: (scale * 0.25) + 1 });
              //   // lineRef.current.style.transform = `scale(${scale * 0.25 + 1})`;
              // }
            }
          });
        }, 400 )

      }, 10)

    }else{

      gsap.fromTo(wrapperRef.current,
        {opacity: 1, scale:1 },
        {opacity: 0, scale:.6, duration:1, ease: 'Power1.easeInOut', onComplete: () => {
          setIsVisible(false)
      }});
      gsap.to(lineRef.current, {
        strokeDashoffset: 400,
        //strokeDasharray: 1,
        duration: .5,
        ease: 'Power3.easeInOut',
      });
    }

    /*
      let proxy = { scale: 1 },
      clamp = gsap.utils.clamp(1, 1.1)
      gsap.set(wrapperRef.current, {force3D: true});

      gsap.from(lineRef.current, {
        strokeDashoffset: 300,
        duration: 10,
        transformOrigin: 'center center',
        ease: 'none',
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: '200%',
          onUpdate: (self) => {
            const velocity = (self.getVelocity() < 0) ? self.getVelocity() *-1 : self.getVelocity()
            const scale = clamp(velocity / 300)
            if (scale > proxy.scale) {
              proxy.scale = scale;
              //gsap.to(proxy, {skew: 0, duration: 0.8, ease: "power3", overwrite: true, onUpdate: () => skewSetter(proxy.skew)});
              gsap.to(proxy, {scale: 1, duration: 1, ease: "power3", overwrite: true, onUpdate: () =>
                gsap.set(wrapperRef.current, {scale:proxy.scale})
              });
            }
          }
        },
      });
    */  

  }, [isActive, isActiveMobile]);

  return (
    <div className={s.circle__draw} ref={containerRef} 
            style={{ width: isMobile ? widthMobile : width, 
                      top: isMobile ? topMobile : top, 
                      left: isMobile ? leftMobile : left }}>

        { isVisible && shape === 'circle' && (
          <svg
            ref={wrapperRef}
            viewBox="0 0 220 220"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              ref={lineRef}
              className="path"
              strokeMiterlimit="10"
              vectorEffect="non-scaling-stroke"
              d="
                M110,220
                a110,110 0 0, 1 0,-220
                a110,110 0 0, 1 0,220
              "
              strokeDasharray={strokeDashArray}
              strokeWidth={strokeWidth} />
          </svg>
        )}

        { isVisible && shape === 'square' && (
          <svg
            ref={wrapperRef}
            viewBox="0 0 188 188"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
              ref={lineRef}
              x="1"
              y="1"
              width="186"
              height="186"
              vectorEffect="non-scaling-stroke"
              strokeDasharray={strokeDashArray}
              strokeWidth={strokeWidth} />
          </svg>
        )}


    </div>
  );
};